<template>
  <div>
    <v-layout wrap justify-center fill-height>
      <v-flex xs12 sm12 md3 lg2 hidden-sm-and-down v-if="storage && appLogin">
        <v-navigation-drawer class="bg1" v-model="sideNav" clipped width="100%" height="100%" permanent>
          <v-layout wrap justify-center>
            <v-flex xs12 text-center pa-2 pl-4 align-self-center>
              <v-layout wrap justify-center>
                <v-flex xs12 text-left>
                  <v-flex xs12 pt-2>
                    <div style="border-bottom: 1px solid #000"></div>
                  </v-flex>
                  <v-list-group :value="false" no-action :active-class="`white--text`">
                    <template v-slot:activator>
                      <v-list-item-title class="text-left"><span style="
                                  font-family: sedanRegular;
                                  font-size: 14px;
                                  color: #000;
                                  cursor: pointer;
                                ">Land Assets</span></v-list-item-title>
                    </template>
                    <v-flex xs12 pt-2>
                      <div style="border-bottom: 1px solid #000"></div>
                    </v-flex>
                    <v-list-item v-for="list in land" :key="list.title" :to="list.route"
                      :active-class="`rgb(136, 182, 227)`" link dense style="padding-left: 40px;">
                      <v-list-item-title><span style="
                                  font-family: sedanRegular;
                                  font-size: 12px;
                                  color: #000;
                                  cursor: pointer;
                                ">{{ list.name }}
                        </span></v-list-item-title>
                    </v-list-item>
                  </v-list-group>
                  <v-flex xs12 pt-2>
                    <div style="border-bottom: 1px solid #000"></div>
                  </v-flex>
                  <v-list-group :value="false" no-action :active-class="`white--text`">
                    <template v-slot:activator>
                      <v-list-item-title class="text-left"><span style="
                                  font-family: sedanRegular;
                                  font-size: 14px;
                                  color: #000;
                                  cursor: pointer;
                                ">Units</span></v-list-item-title>
                    </template>
                    <v-flex xs12 pt-2>
                      <div style="border-bottom: 1px solid #000"></div>
                    </v-flex>
                    <v-list-item v-for="list in units" :key="list.title" :to="list.route"
                      :active-class="`rgb(136, 182, 227)`" link dense style="padding-left: 40px;">
                      <v-list-item-title><span style="
                                  font-family: sedanRegular;
                                  font-size: 12px;
                                  color: #000;
                                  cursor: pointer;
                                ">{{ list.name }}
                        </span></v-list-item-title>
                    </v-list-item>
                  </v-list-group>
                  <v-flex xs12 pt-2>
                    <div style="border-bottom: 1px solid #000"></div>
                  </v-flex>
                  <v-list-group :value="false" no-action :active-class="`white--text`">
                    <template v-slot:activator>
                      <v-list-item-title class="text-left"><span style="
                                  font-family: sedanRegular;
                                  font-size: 14px;
                                  color: #000;
                                  cursor: pointer;
                                ">Bonds</span></v-list-item-title>
                    </template>
                    <v-flex xs12 pt-2>
                      <div style="border-bottom: 1px solid #000"></div>
                    </v-flex>
                    <v-list-item v-for="list in bonds" :key="list.title" :to="list.route"
                      :active-class="`rgb(136, 182, 227)`" link dense style="padding-left: 40px">
                      <v-list-item-title class="text-left"><span style="
                                  font-family: sedanRegular;
                                  font-size: 12px;
                                  color: #000;
                                  cursor: pointer;
                                ">{{ list.name }}
                        </span></v-list-item-title>
                    </v-list-item>
                  </v-list-group>
                </v-flex>
                <v-flex xs12 pt-2>
                  <div style="border-bottom: 1px solid #000"></div>
                </v-flex>
                <v-layout wrap justify-center>
                  <v-flex xs12 text-left py-2 pa-2>
                    <span style="
                              font-family: sedanRegular;
                              font-size: 14px;
                              color: #000;
                              cursor: pointer;
                            ">Corporate Sustainability Disclosures
                    </span>
                  </v-flex>
                </v-layout>
                <v-flex xs12 pt-2>
                  <div style="border-bottom: 1px solid #000"></div>
                </v-flex>
                <v-layout wrap justify-center>
                  <v-flex xs12 text-left pa-2>
                    <span style="
                              font-family: sedanRegular;
                              font-size: 14px;
                              color: #000;
                              cursor: pointer;
                            ">Climate & Biodiversity Projects
                    </span>
                  </v-flex>
                </v-layout>
                <v-flex xs12 pt-2>
                  <div style="border-bottom: 1px solid #000"></div>
                </v-flex>
                <v-layout wrap justify-center>

                  <v-flex xs12 text-left pa-2>
                    <router-link to="/assetList" style="text-decoration: none">
                      <span style="
                              font-family: sedanRegular;
                              font-size: 14px;
                              color: #000;
                              cursor: pointer;
                            ">View Transactions
                      </span>
                    </router-link>

                  </v-flex>
                </v-layout>
                <v-flex xs12 pt-2>
                  <div style="border-bottom: 1px solid #000"></div>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <!-- <v-layout wrap justify-center align-end style="padding-top:100px">
            <v-flex xs10 pt-2 align-self-end py-5>
              <div style="border-bottom: 1px solid #000"></div>
              <v-layout wrap>
                <v-flex xs12 pt-2>
                  <span
                    style="
                      font-family: robotoMedium;
                      color: #000;
                      font-size: 14px;
                    "
                    >2022 ECOLedger ltd</span
                  >
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout> -->
        </v-navigation-drawer>
      </v-flex>
      <v-flex xs12 sm12 :md9="storage && appLogin" :md12="!storage && !appLogin" :lg10="storage && appLogin"
        :lg12="!storage && !appLogin">
        <router-view></router-view>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  props: ["storage"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      sideNav: true,
      list: [
        {
          name: "Manage Assets",
        },
        { name: "Bonds" },
        {
          name: "Corporate Sustainability Disclosures",
        },
        {
          name: "Climate & Biodiversity Projects",
        },
        {
          name: "View Transactions",
          route: "/assetList",
          type: "link",
        },
      ],
      land: [
        {
          name: "Register Asset",
          route: "/assetRegister",
          type: "link",
        },
        {
          name: "Update Asset",
          route: "/landList",
          type: "link",
        },
        {
          name: "Transfer Asset",
          route: "/assetListTransfer",
          type: "link",
        },
      ],
      units: [
        {
          name: "Register Units",
          route: "/unitsRegister",
          type: "link",
        },
        {
          name: "Update Units",
          route: "/unitList",
          type: "link",
        },
        {
          name: "Sale/Purchase of Units",
          route: "/unitListTransfer",
          type: "link",
        },
        {
          name: "Transfer Units Asset",
          route: "/unitOwnershipTransferList",
          type: "link",
        },
      ],
      asssets: [
        {
          name: "Register Asset",
          route: "/assetRegister",
          type: "link",
        },
        {
          name: "Update Asset",
          route: "/landList",
          type: "link",
        },
        {
          name: "Transfer Asset",
          route: "/assetListTransfer",
          type: "link",
        },
        {
          name: "Register Units",
          route: "/unitsRegister",
          type: "link",
        },
        {
          name: "Update Units",
          route: "/unitList",
          type: "link",
        },
        {
          name: "Sale/Purchase of Units",
          route: "/unitListTransfer",
          type: "link",
        },
        {
          name: "Transfer Units Asset",
          route: "/unitOwnershipTransferList",
          type: "link",
        },
        {
          name: "Block Crawler",
          route: "/blockCrawler",
          type: "link",
        },
      ],
      bonds: [
        {
          name: "Green Bond",
          route: "/greenBond",
          type: "link",
        },
        {
          name: "Social Bond",
        },
        {
          name: "Sustainability Bond",
        },
        {
          name: "External Review-Green Bond",
        },
        {
          name: "External Review-Social Bond",
        },
        {
          name: "External Review-Sustainability Bond",
        },
      ],
    };
  },
  computed: {
    appLogin() {
      return this.$store.state.isLoggedIn;
    },
    appUser() {
      return this.$store.state.userData;
    },
  },
};
</script>
<style>
.changes:hover {
  font-weight: bold;
}

.bg1 {
  background: linear-gradient(174.14deg, #edf1fd 8.2%, #96cdf7 76.78%);
}
</style>
